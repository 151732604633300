import { AttivitaDTO } from '@api/client';
import { useAppDispatch } from 'core/store/hooks';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ActivityCard from 'shared/components/activity-card';
import AppButton from 'shared/design-system/components/app-button';
import AppNoResults from 'shared/design-system/components/app-no-results';
import AppPage from 'shared/design-system/components/app-page';
import { activitiesGetDetails } from '../_redux/actions';
import ActivityEditModal from '../activity-edit-modal';

interface Props {
  isLibrary?: boolean;
}

const ActivityDetailsPage: React.FC<Props> = props => {
  const { isLibrary } = props;

  const { entId } = useParams();

  const dispatch = useAppDispatch();
  const location = useLocation();

  const [data, setData] = useState<AttivitaDTO>();
  const [showEditForm, setShowEditForm] = useState<boolean>(false);
  const [showOrderForm, setShowOrderForm] = useState<boolean>(false);

  const handleNewClick = () => {
    setShowEditForm(true);
  };

  const handleOrderClick = () => {
    setShowOrderForm(true);
  };

  const getData = () => {
    if (!entId) return console.error('no entId found');

    dispatch(activitiesGetDetails(Number(entId)))
      .unwrap()
      .then(res => {
        if (res) setData(res);
      });
  };
  useEffect(() => {
    getData();
  }, [entId]);

  return (
    <>
      <AppPage
        goBack
        title='Dettaglio attività'
        subtitle="Crea, modifica o elimina le attività da visualizzare all'interno dell'applicazione."
        pathNames={(location?.state?.activitiesDetails as AttivitaDTO[])?.map(
          x => x?.name || '',
        )}
        actions={
          <AppButton onClick={() => handleNewClick()} className='w-full md:w-auto'>
            <i className='fas fa-plus me-2' />
            Aggiungi
          </AppButton>
        }
      >
        {data?.children && data?.children?.length > 0 ? (
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-3 my-3'>
            {data?.children?.map((item, i) => (
              <ActivityCard
                key={`action_${i}`}
                activity={item}
                onEdit={() => getData()}
                onDelete={() => getData()}
                isLibrary={isLibrary}
              />
            ))}
          </div>
        ) : (
          <AppNoResults />
        )}
      </AppPage>

      <ActivityEditModal
        show={showEditForm}
        onHide={e => {
          if (e) getData();
          setShowEditForm(false);
        }}
        isLibrary={isLibrary}
      />
    </>
  );
};
export default ActivityDetailsPage;
