/* tslint:disable */
/* eslint-disable */
/**
 * David Master Api
 * db: david-master-svil
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { UserDTOFromJSON, UserDTOToJSON, } from './UserDTO';
/**
 * Check if a given object implements the AttivitaDTO interface.
 */
export function instanceOfAttivitaDTO(value) {
    let isInstance = true;
    return isInstance;
}
export function AttivitaDTOFromJSON(json) {
    return AttivitaDTOFromJSONTyped(json, false);
}
export function AttivitaDTOFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'order': !exists(json, 'order') ? undefined : json['order'],
        'children': !exists(json, 'children') ? undefined : (json['children'] === null ? null : json['children'].map(AttivitaDTOFromJSON)),
        'user': !exists(json, 'user') ? undefined : UserDTOFromJSON(json['user']),
    };
}
export function AttivitaDTOToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'image': value.image,
        'order': value.order,
        'children': value.children === undefined ? undefined : (value.children === null ? null : value.children.map(AttivitaDTOToJSON)),
        'user': UserDTOToJSON(value.user),
    };
}
