import { AttivitaCreateRequest, AttivitaDTO } from '@api/client';
import { authUserSelector } from 'core/auth/_redux/selectors';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AppButton from 'shared/design-system/components/app-button';
import AppDropzone from 'shared/design-system/components/app-dropzone';
import AppInput from 'shared/design-system/components/app-input';
import AppModal from 'shared/design-system/components/app-modal';
import { AppToastService } from 'shared/design-system/components/app-toast/app-toast.service';
import { FileUtils } from 'shared/utility/files.utils';
import * as Yup from 'yup';
import {
  activitiesCreate,
  activitiesEdit,
  activitiesGetDetails,
} from '../_redux/actions';

interface Props {
  show: boolean;
  onHide: (reload?: boolean) => void;

  entId?: number | undefined;
  isLibrary?: boolean;
}

const ActivityEditModal: React.FC<Props> = props => {
  const { show, entId, isLibrary, onHide } = props;
  const { entId: parentId } = useParams();

  const dispatch = useAppDispatch();

  const user = useAppSelector(authUserSelector);

  const [data, setData] = useState<AttivitaDTO | undefined>();

  const handleHide = (reload?: boolean) => {
    setData(undefined);
    onHide(reload);
  };

  const getDetails = () => {
    if (!entId) return;

    dispatch(activitiesGetDetails(entId))
      .unwrap()
      .then(res => setData(res));
  };

  const handleSubmit = async (values: FormValues) => {
    let request;

    let _request: AttivitaCreateRequest = {
      ...(entId ? data : {}),
      name: values.name,
      isLibrary: isLibrary,
      order: values?.order,
      userEmail: values.userEmail != '' ? values.userEmail : undefined,
    };

    if (parentId) _request.parentId = Number(parentId);

    if (values.image) {
      await FileUtils.toBase64(values.image).then(res => {
        _request.image = res;
      });
    }

    if (entId) {
      request = dispatch(activitiesEdit({ id: entId, values: _request }));
    } else request = dispatch(activitiesCreate(_request));

    request.unwrap().then(res => {
      if (res) {
        AppToastService.success(
          `Attività ${entId ? 'modificata' : 'aggiunta'} con successo`,
        );

        handleHide(true);
      }
    });
  };

  useEffect(() => {
    if (show) getDetails();
  }, [show]);

  if (!show || (entId && !data)) return null;

  return (
    <AppModal
      show={show}
      onClose={() => handleHide()}
      title={`${entId ? 'Modifica' : 'Nuova'} Attività`}
      block
    >
      <Formik<FormValues>
        initialValues={{
          name: data?.name || '',
          image: data?.image ? FileUtils.fromBase64(data.image) : undefined,
          userEmail: data?.user?.email || '',
          order: data?.order || 0,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(),
          image: Yup.string().required(),
        })}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleSubmit: _handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => {
          const handleAddFile = (file: File) => {
            setFieldValue('image', file);
          };

          const handleDeleteFile = () => {
            setFieldValue('image', undefined);
          };

          return (
            <form
              onSubmit={_handleSubmit}
              className='grid grid-cols-1 gap-3'
              autoComplete='off'
            >
              <AppInput
                label='Nome'
                name='name'
                value={values.name!}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors?.name && touched?.name}
                error={errors?.name}
                autoFocus
              />

              {isLibrary != true && user?.isAdmin && (
                <AppInput
                  label='User Email'
                  name='userEmail'
                  value={values.userEmail!}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors?.userEmail && touched?.userEmail}
                  error={errors?.userEmail}
                  autoFocus
                />
              )}

              <AppInput
                type='number'
                label='Posizione'
                name='order'
                value={values.order!}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors?.order && touched?.order}
                error={errors?.order}
                autoFocus
              />

              <AppDropzone
                accept='image'
                label='Immagine'
                file={values?.image}
                onDrop={handleAddFile}
                onDelete={handleDeleteFile}
                isInvalid={!!errors?.image && touched?.image}
                error={errors?.image}
              />

              <div className='grid grid-cols-1 md:grid-cols-2 form-actions'>
                <div className='order-2 md:order-1'>
                  <AppButton
                    color='white'
                    onClick={() => handleHide()}
                    className='w-full md:w-auto'
                  >
                    Annulla
                  </AppButton>
                </div>

                <div className='text-end order-1 md:order-2'>
                  <AppButton type='submit' className='w-full md:w-auto'>
                    Salva
                  </AppButton>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </AppModal>
  );
};
export default ActivityEditModal;

interface FormValues {
  name: string;
  image?: File;
  parentId?: string;
  userEmail?: string;
  order?: number;
}
