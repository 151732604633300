// import { ErrorResponseDto, ErrorResponseDtoFromJSON } from "@api/client";
// import AuthService from "core/services/auth.service";
import { Configuration } from '@api/client';

import AuthService from 'core/auth/auth.service';
import { AppToastService } from 'shared/design-system/components/app-toast/app-toast.service';

const getConfiguration = () => {
  const c = new Configuration();

  const token = AuthService.getToken();

  const configuration: any = {
    basePath: process.env.REACT_APP_BASE_API_URL,
    middleware: c.middleware,
    fetchApi: c.fetchApi,
    queryParamsStringify: c.queryParamsStringify,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    // accessToken: token,
  };

  return configuration;
};

function Utf8ArrayToStr(array: Uint8Array) {
  var out, i, len, c;
  var char2, char3;

  out = '';
  len = array.length;
  i = 0;
  while (i < len) {
    c = array[i++];
    switch (c >> 4) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        // 0xxxxxxx
        out += String.fromCharCode(c);
        break;
      case 12:
      case 13:
        // 110x xxxx   10xx xxxx
        char2 = array[i++];
        out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f));
        break;
      case 14:
        // 1110 xxxx  10xx xxxx  10xx xxxx
        char2 = array[i++];
        char3 = array[i++];
        out += String.fromCharCode(
          ((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0),
        );
        break;
    }
  }
  return out;
}

function JSONTryParse(str: string): Object {
  try {
    return JSON.parse(str);
  } catch (e) {
    return {
      error: `JSONTryParse ${e}`,
      error_code: str,
      error_description: str,
    };
  }
}

function isErrorResponseDto(error: Object): boolean {
  return error.hasOwnProperty('message') && error.hasOwnProperty('statusCode');
}

export const decodeError = async (
  error: Response | undefined,
): Promise</* ErrorResponseDto | */ any> => {
  let message = error?.statusText;
  let statusCode = error?.status;
  let errorType = undefined;

  // debugger;
  if (error?.body) {
    const errorTextReadable = await error?.body?.getReader().read();
    if (errorTextReadable.value) {
      const errorTextStr = Utf8ArrayToStr(errorTextReadable.value);
      const errorObject = errorTextStr ? JSONTryParse(errorTextStr) : {};
      console.log('errorObject', errorObject);

      if ((errorObject as any)?.error) {
        message = (errorObject as any)?.description;
      }
      // if (isErrorResponseDto(errorObject)) {
      // 	const errorResponse = ErrorResponseDtoFromJSON(errorObject);
      // 	errorResponse.message && (message = errorResponse.message);
      // 	errorResponse.statusCode && (statusCode = errorResponse.statusCode);
      // 	errorType = errorResponse.errorType;
      // }
    }
  }
  if (message) {
    return {
      message: message,
      statusCode: statusCode,
      errorType: errorType,
    };
  } else {
    return {
      message: 'Si è verificato un errore generico',
      statusCode: 999,
      errorType: errorType,
    };
  }
};

export const errorHandler = async (error: any, decoded?: boolean) => {
  console.log(
    'errorHandler',
    // error,
    error.response,
    // error.constructor,
    // error.constructor.name
  );

  // if (error?.response?.status == 401) {
  //   AuthService.logout();
  // }

  const errorResponse /*: ErrorResponseDto */ = decoded
    ? error
    : await decodeError(error?.response);
  console.log('errorResponse', errorResponse);
  // dispatch(setGeneralError(errorResponse));

  AppToastService.error(errorResponse?.message);
};

export default getConfiguration;
