/* tslint:disable */
/* eslint-disable */
/**
 * David Master Api
 * db: david-master-svil
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
/**
 * Check if a given object implements the AttivitaCreateRequest interface.
 */
export function instanceOfAttivitaCreateRequest(value) {
    let isInstance = true;
    return isInstance;
}
export function AttivitaCreateRequestFromJSON(json) {
    return AttivitaCreateRequestFromJSONTyped(json, false);
}
export function AttivitaCreateRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'order': !exists(json, 'order') ? undefined : json['order'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'userEmail': !exists(json, 'userEmail') ? undefined : json['userEmail'],
        'isLibrary': !exists(json, 'isLibrary') ? undefined : json['isLibrary'],
    };
}
export function AttivitaCreateRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'image': value.image,
        'order': value.order,
        'parentId': value.parentId,
        'userEmail': value.userEmail,
        'isLibrary': value.isLibrary,
    };
}
